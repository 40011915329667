


































import {Component, Vue, Inject} from 'vue-property-decorator';
import Footer from '../components/Footer.vue'
import TenantSwitch from '../components/tenant-switch.vue'
import LanguageSwitch from '../components/language-switch.vue'
import iView from 'view-design';
import AbpBase from '../lib/abpbase'
import SendPasswordResetCodeInput from "@/store/entities/sendpasswordresetcodeinput";

@Component({
    components: {Footer}
})
export default class ForgotPassword extends AbpBase {
    private input: SendPasswordResetCodeInput = new SendPasswordResetCodeInput();

    async back() {
        await this.$router.push('login');
    }

    async save() {
        (this.$refs.resetPassword as any).validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: 'app/sendPasswordResetCode',
                    data: this.input
                })
                await this.$router.push('login');
            }
        });
    }

    rules = {
        email: [{
            required: true,
            message: this.L('FieldIsRequired', undefined, this.L('Email')),
            trigger: 'blur'
        }, {type: 'email'}],
    }

    created() {
        this.input = new SendPasswordResetCodeInput();
    }
}
